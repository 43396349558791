import styled from 'styled-components';

const LabelText = styled.span<{ require?: boolean }>`
  display: inline-block;
  position: relative;
  padding-right: 8px;
  line-height: 1.7;
  ${({ require }) => {
    if (require) {
      return `
      &::after {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        background: #1cabe2;
        border-radius: 100%;
        vertical-align: middle;
        margin: -4px 0 0 4px;
      }
        `;
    }
    return ``;
  }}
`;

export default LabelText;
